<template>
    <div class="row" style="margin-top: 5px;" >
        <div class="col-md-8">
            <input type="text" placeholder="Serial no." v-model="temp" @input="valuechanged()"
                 class="form-control form-cascade-control" style="height: 28px">
                 <!-- :disabled="this.$parent.loading ? true : false" -->
        </div>
        <div class="col-md-4">
            <input type="text" placeholder="Colour" v-model="newcolour" @input="valuechanged()"
                :disabled="this.$parent.loading ? true : false" class="form-control form-cascade-control"
                style="height: 28px" />
        </div>
    </div>
    <!-- <div class="row" style="margin-top: 5px;" v-else>
        <div class="col-md-8">
            <div class="form-control form-cascade-control" style="height: 33px"><span>{{ this.temp }}</span></div>
        </div>
        <div class="col-md-4">
            <div class="form-control form-cascade-control" style="height: 33px"><span>{{ this.newcolour }}</span></div>

        </div>
    </div> -->
</template>
<script>
export default {
    props: ['value'],
    data() {
        return {
            temp: '',
            newcolour: ''
        }
    },
    computed: {

    },
    watch: {
        value: function () {
            this.initialize()
        }
    },
    mounted() {
        this.initialize()
    },
    methods: {
        valuechanged() {
            let param = { barcode: this.temp, colour: this.newcolour }
            this.$emit('updateedititem', param)
        },
        initialize() {
            // console.log("initialize")
            this.temp = this.value.barcode
            this.newcolour = this.value.colour
        }
    }
}
</script>